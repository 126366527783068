import { At, Whatsapp } from "react-bootstrap-icons"
import Img from "../../../../Public/Img"
import { useEffect, useState } from "react"
import Cookies from "js-cookie"
import Button from "../../../../Public/Button"
import InputMethod from "../../../../Public/InputMethod"
import axios from "axios"

const TutorView = ({data, api}) => {
    const [adminDetails] = useState(JSON.parse(Cookies.get("adminDetails")))
    const [watchVV, setWatchVV] = useState(false)
    const [tutor_ver, setTutor_ver] = useState(data.tutor_verified)
    const [wait, setWait] = useState(false)

    useEffect(() => {
        setTutor_ver(data.tutor_verified)
    }, [data])

    const Verify_Tutor = async (e) => {
        e.preventDefault()
        setWait(true)
        let formData = new FormData()
        formData.append("authorization","2dkn3KN38JJ*d!den_-dkDN") 
        formData.append("run_type","/admin/verify_tutor")
        formData.append("tutor_id",data.tutor_id)
        formData.append("admin_id", adminDetails.admin_id)
        
        try {
            const response = await axios.post(api, formData);
            setWait(false)
            if (!response.data.success) {
                alert("Failed!")
                return
            } 
            setTutor_ver("1")
            // editReceipt(data.id, value)
            // console.log(response.data)
        } catch(err) {
            setWait(false)
            alert("Network Error, Please Check connection and try again")
            console.log(err)
        }
    }
    const action_tutor_req = async (action) => {
        setWait(true)
        let formData = new FormData()
        formData.append("authorization","2dkn3KN38JJ*d!den_-dkDN") 
        formData.append("run_type","/admin/action_tutor_req")
        formData.append("tutor_id",data.tutor_id)
        formData.append("admin_id", adminDetails.admin_id)
        formData.append("action", action)
        
        try {
            const response = await axios.post(api, formData);
            setWait(false)
            if (!response.data.success) {
                alert("Failed!")
                return
            } 
            setTutor_ver(action==="true" ? "3" : "0")
            // editReceipt(data.id, value)
            // console.log(response.data)
        } catch(err) {
            setWait(false)
            alert("Network Error, Please Check connection and try again")
            console.log(err)
        }
    }
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            margin: "8px 0px",
            border: ".5px solid #eee",
            padding: "5px 8px"
        }}>
            <div style={{
                textTransform: "capitalize",
                display: "flex",
                flexWrap: "wrap"
            }}>
                <div style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "75px",
                    overflow: "hidden"
                }}>
                    <Img src={api+data.passport} alt="" style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        cursor: "pointer"
                    }} />
                </div>
                <div style={{margin: "auto 8px", fontSize: "14px"}}>
                    {String(data.firstname)?.toLowerCase()} {String(data.middlename)?.toLowerCase()} {String(data.surname)?.toLowerCase()} 
                    <br/>
                    <span style={{fontSize: "13px", display: "flex"}}><Whatsapp style={{margin: "auto 2px", display: "block"}} /><i style={{margin: "auto 2px", display: "block"}}>{data.whatsapp}</i></span>
                    <span style={{fontSize: "13px", textTransform: "lowercase", display: "flex"}}><At style={{margin: "auto 2px", display: "block"}} /><i style={{margin: "auto 2px", display: "block"}}>{data.email}</i></span>
                </div>
            </div>
            <hr style={{width: "80%", margin: "8px auto" }} />
            <div style={{

            }}>
                <div>
                    <table className="table table-striped" style={{textTransform: "capitalize", fontSize: "14px"}}>
                        <thead>
                            <tr>
                                <th colSpan={2} style={{fontSize: "14px", background: "inherit"}}>
                                    Details
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Verification process:
                                </td>
                                <td>
                                    {
                                        tutor_ver === "1" ?
                                        <span style={{color: "#28a745"}}>Verified</span> :
                                        tutor_ver === "0" ?
                                        <span style={{color: "#dc3545"}}>Not Verified</span> :
                                        data.verification_video ?
                                        <a href={api+data.verification_video} target="_blank" rel="noreferrer" onClick={() => setWatchVV(true)}>
                                            Watch Verification Video
                                        </a> :
                                        "Not yet uploaded"
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Gender:
                                </td>
                                <td>
                                    {data.gender?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Date of Birth:
                                </td>
                                <td>
                                    {data.dob?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Other Number:
                                </td>
                                <td>
                                    {data.phone?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    1st Guarantor Name:
                                </td>
                                <td>
                                    {data.guarantor_name_1?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    1st Guarantor Phone:
                                </td>
                                <td>
                                    {data.guarantor_phone_1?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    1st Guarantor Address:
                                </td>
                                <td>
                                    {data.guarantor_address_1?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    1st Guarantor Relationship:
                                </td>
                                <td>
                                    {data.guarantor_occupation_1?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    2nd Guarantor Name:
                                </td>
                                <td>
                                    {data.guarantor_name_2?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    2nd Guarantor Phone:
                                </td>
                                <td>
                                    {data.guarantor_phone_2?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    2nd Guarantor Address:
                                </td>
                                <td>
                                    {data.guarantor_address_2?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    2nd Guarantor Relationship:
                                </td>
                                <td>
                                    {data.guarantor_occupation_2?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Class Type:
                                </td>
                                <td>
                                    {data.class_type?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Class Session:
                                </td>
                                <td>
                                    {data.class_session?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Teaching Experience:
                                </td>
                                <td>
                                    {data.teaching_exp?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Qualification:
                                </td>
                                <td>
                                    {data.qualifications_?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Area of Study:
                                </td>
                                <td>
                                    {data.area_of_study?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Subject:
                                </td>
                                <td>
                                    {data.subject?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Account Number:
                                </td>
                                <td>
                                    {data.acct_number?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Account Name:
                                </td>
                                <td>
                                    {data.acct_name?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Bank Name:
                                </td>
                                <td>
                                    {data.bank_name?.toLowerCase()}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {
                    Number(adminDetails.pos) >3 || Number(adminDetails.pos) === 2 ? 
                    wait ?
                        "Please wait":
                        <div className="sm_ele_grid">
                            {
                                tutor_ver==="2" ?
                                <Button name="Send Tutor WhatsApp Facilitator link for interview" styles={{fontSize: "12px", textAlign: "center"}} onclick={() => {
                                    if (window.confirm("Send Tutor WhatsApp Group Link?")) {
                                        if (watchVV) {
                                            action_tutor_req("true")
                                        } else {
                                            alert("Watch verification video first")
                                        }
                                    }
                                }} /> :
                                ""
                            }
                            {
                                tutor_ver==="2" ?
                                <Button name="Decline Tutor request" styles={{fontSize: "12px", textAlign: "center"}} onclick={() => {
                                    if (window.confirm("Decline Tutor Request?")) {
                                        action_tutor_req("false")
                                    }
                                }} error /> :
                                ""
                            }
                            {
                                tutor_ver==="3" ?
                                <form onSubmit={Verify_Tutor} style={{fontSize: "16px"}}>
                                    <InputMethod name="ver_tutor" label="This Tutor has passed the interview and ready to be employed" checklist style={{border: "none"}} required/>
                                    <Button name="Verify Tutor" styles={{fontSize: "16px", textAlign: "center", width: "100%"}} form/>
                                </form> :
                                ""
                            }
                        </div>
                    :
                    "" 
                }
            </div>
        </div>
    )
}

export default TutorView