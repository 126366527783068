import { useCallback, useEffect, useState } from "react"
import Button from "../../../Public/Button"
import Cookies from "js-cookie"
import axios from "axios"
import ServerLoading from "../../../Public/ServerLoading"
import ReadPolicy from "./components/ReadPolicy"

const StartPage = (props) => {
    const admin_id = Cookies.get("mdjdiowdadmin") || ""
    const [disabled, setDisabled] = useState(true)
    const [adminDetails, setAdminDetails] = useState("")
    const [role, setRole] = useState(0)
    const [policy, setPolicy] = useState(false)

    const fetchAdminDetails = useCallback( async () => {
        setDisabled(true)
        let formData = new FormData()
        formData.append("authorization","2dkn3KN38JJ*d!den_-dkDN") 
        formData.append("run_type","/admin/details")
        formData.append("admin_id",admin_id)
        try {
            const response = await axios.post(props.api, formData);
            if (!response.data.success) {
                if (!response.data.message) {
                    alert("an error occurred")
                } else {
                    alert(response.data.message)
                }
                setDisabled(false)
            } else {
                setRole(Number(response.data.user.pos))
                Cookies.set("adminDetails", JSON.stringify(response.data.user),{path: '/', expires: new Date(Date.now()+21212592000)})
                if (response.data.user.read_policy==="0") {
                    setPolicy(true)
                    setDisabled(false)
                    return
                }
                setPolicy(false)
                setAdminDetails(response.data.user)
                setDisabled(false)
            }
        } catch(err) {
            alert("Network Error, Please Check connection and refresh the page...")
        }
    }, [admin_id, props.api] )

    const t0_activities = () => {
        //normal admin
        if (role>=0) {
            return (
                <>
                
                </>
            )
        }
        return
    }

    const t1_activities = () => {
        // student admin
        if (role>=1) {
            return (
                <>
                
                </>
            )
        }
        return
    }

    const t2_activities = () => {
        // tutor and student admin
        if (role>=2) {
            return (
                <>
                    <Button name="Manage Tutor" styles={{textAlign: "center", fontSize: "15px"}} onclick={() => props.setLink("/ver_tutor")} />
                </>
            )
        }
        return
    }

    const t3_activities = () => {
        //tutor, student and financial admin
        if (role>=3) {
            return (
                <>
                    <Button name="Student Payment Receipt" styles={{textAlign: "center", fontSize: "15px"}} onclick={() => props.setLink("/conf_pay_rec")} />
                    <Button name="Manage Students" styles={{textAlign: "center", fontSize: "15px"}} onclick={() => props.setLink("/manage_student")} />

                </>
            )
        }
        return ""
    }

    const t4_activities = () => {
        //boss || overseer
        if (role>=4) {
            return (
                <>
                
                </>
            )
        }
        return
    }

    const t5_activities = () => {
        //controller || builder
        if (role>=5) {
            return (
                <>
                
                </>
            )
        }
        return
    }

    useEffect( () => {
        fetchAdminDetails()
        return
    }, [fetchAdminDetails])
    return (
        <>
            <ServerLoading display={disabled} />
            <div style={{
                padding: "10px"
            }}>
                {
                    adminDetails ?
                    <>
                        <h2 style={{textTransform: "capitalize", fontWeight: "bolder"}}>Welcome, Admin {adminDetails.firstname.toLowerCase()}</h2>
                    </> :
                    ""
                }
                <h4 style={{fontWeight: "bolder", marginTop: "15px"}}>
                    Activities: 
                </h4>
                <div className="ele_grid">
                    {t0_activities()}
                    {t1_activities()}
                    {t2_activities()}
                    {t3_activities()}
                    {t4_activities()}
                    {t5_activities()}
                </div>
            </div>
            {role<=3 && policy && <ReadPolicy setPolicy={setPolicy} action={fetchAdminDetails} role={role} api={props.api} />}
        </>
    )
}

export default StartPage