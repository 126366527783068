import { useEffect, useCallback, useRef, useState } from "react";
import axios from "axios";
import Button from "../../../Public/Button";
import { ArrowClockwise, ChevronDoubleUp } from "react-bootstrap-icons";
import ViewTime from "../../../Public/ViewTime";
import TutorView from "./components/TutorView";

const ConfirmTutor = ({ api }) => {
  const [tutors, setTutors] = useState("");
  const [limit, setLimit] = useState(10);
  const [notification, setNotification] = useState(false);
  const [numbTutors, setNumbTutors] = useState("Loading...");
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);

  const containerBody = useRef(null);
  const eventSourceRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);

  function convertTimestampToJS(timestampString) {
    const [datePart, timePart] = timestampString.split(" ");
    const [year, month, day] = datePart.split("/");
    const [time, meridian] = timePart.split(/([ap]m)/i);
    const [hour, minute, second] = time.split(":");

    let hour24 = parseInt(hour, 10);
    if (meridian?.toLowerCase() === "pm" && hour24 !== 12) {
      hour24 += 12;
    } else if (meridian?.toLowerCase() === "am" && hour24 === 12) {
      hour24 = 0;
    }

    const jsDate = new Date(year, month - 1, day, hour24, minute, second);
    return jsDate.getTime();
  }

  const initializeEventSource = () => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }

    const eventSource = new EventSource(
      "https://server.welearn.com.ng/sse/fetch_all_tutor_details.php"
    );
    eventSourceRef.current = eventSource;

    eventSource.onmessage = function (event) {
      try {
        const newMessages = JSON.parse(event.data);
        setNumbTutors(newMessages.length);
        if (!tutors) {
          setTutors(newMessages.slice(0, 10));
        } else {
          const oldMessages = tutors.slice(0, 10);
          if (
            JSON.stringify(oldMessages) !==
            JSON.stringify(newMessages.slice(0, 10))
          ) {
            setTutors([
              ...newMessages.slice(0, 10),
              ...tutors.slice(10, tutors.length),
            ]);
            setNotification(true);
          }
        }
      } catch (error) {
        console.error("Error parsing SSE data:", error);
      }
        // console.log(event.data)
    };

    eventSource.onerror = function (error) {
      console.error("EventSource error:", error);
      eventSource.close();
      if (!reconnectTimeoutRef.current) {
        reconnectTimeoutRef.current = setTimeout(() => {
          initializeEventSource();
          reconnectTimeoutRef.current = null;
        }, 5000); // Reconnect after 5 seconds
      }
    };
  };

  useEffect(() => {
    initializeEventSource();

    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, [tutors]);

  const fetchAllStudentDetails = useCallback(
    async (limit) => {
    //   let formData = new FormData();
    //   formData.append("authorization", "2dkn3KN38JJ*d!den_-dkDN");
    //   formData.append("run_type", "/admin/get_all_tutors");
    //   formData.append("limit", limit);
    //   try {
    //     const response = await axios.post(api, formData);
    //     if (!response.data.length > 0) {
    //       alert("Receipt not available");
    //     } else {
    //       setTutors(response.data);
    //     }
    //   } catch (err) {
    //     alert("Network Error, Please Check connection and refresh the page...");
    //   }
    },
    [api]
  );

  useEffect(() => {
    const handleScroll = () => {
      if (containerBody.current) {
        setShowScrollTopButton(containerBody.current.scrollTop > 50);
      }
    };

    const container = containerBody.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [tutors]);

  return (
    <>
      {notification && (
        <Button
          name={
            <>
              <span
                style={{
                  background: "red",
                  display: "block",
                  top: "2px",
                  position: "absolute",
                  right: "0px",
                  width: "10px",
                  height: "10px",
                  borderRadius: "10px",
                }}
              ></span>
              <ArrowClockwise />
            </>
          }
          styles={{
            borderRadius: "30px",
            padding: "6px 10px",
            fontSize: "14px",
            textAlign: "center",
            position: "absolute",
            right: "30px",
          }}
          onclick={() => {
            setNotification(false);
            containerBody.current?.scrollTo(0, 0);
          }}
        />
      )}
      {showScrollTopButton && (
        <Button
          name={<ChevronDoubleUp />}
          styles={{
            borderRadius: "30px",
            padding: "6px 10px",
            fontSize: "14px",
            textAlign: "center",
            position: "fixed",
            bottom: "80px",
            right: "50px",
            zIndex: "9999"
          }}
          onclick={() => {
            containerBody.current?.scrollTo(0, 0);
          }}
        />
      )}
      {tutors ? (
        tutors.length > 0 ? (
          <>
            <div
              ref={containerBody}
              style={{
                flex: "1",
                overflowY: "scroll",
              }}
            >
                {tutors.map((tutor, key) => (
                  <div key={key}>
                    <div>
                      <span
                        style={{
                          width: "fit-content",
                          height: "fit-content",
                          borderRadius: "50px",
                          padding: "6px 12px",
                          background: "#12529f",
                          color: "#fff",
                          fontSize: "12px",
                        }}
                      >
                        {key + 1}
                      </span>
                      <span
                        style={{
                          marginLeft: "8px",
                          fontSize: "13px",
                          fontStyle: "italic",
                        }}
                      >
                        Registered:{" "}
                        <ViewTime date={convertTimestampToJS(tutor.date_registered)} />
                      </span>
                    </div>
                    <div>
                      <TutorView data={tutor} api={api} />
                    </div>
                  </div>
                ))}
            </div>
          </>
        ) : (
          "No Tutor"
        )
      ) : (
        "Please wait"
      )}
    </>
  );
};
export default ConfirmTutor;
