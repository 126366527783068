import axios from "axios"
import Button from "../../../../Public/Button"
import InputMethod from "../../../../Public/InputMethod"
import AdminDuties from "./AdminDuties"
import Cookies from "js-cookie"
import { useState } from "react"

const ReadPolicy = ({role, setPolicy, action, api}) => {
    const [disabled, setDisabled] = useState(false)

    const admin_id = Cookies.get("mdjdiowdadmin") || ""

    const confirmPolicy = async (e) => {
        e.preventDefault()
        let formData = new FormData()
        formData.append("authorization","2dkn3KN38JJ*d!den_-dkDN") 
        formData.append("run_type","/admin/update_policy")
        formData.append("admin_id",admin_id)
        try {
            const response = await axios.post(api, formData);
            if (!response.data.success) {
                alert("Network error, please try again")
                setDisabled(false)
            } else {
                setDisabled(false)
                setPolicy(false)
                action()
            }
        } catch(err) {
            alert("Network Error, Please Check connection and refresh the page...")
        }
    }

    return (
        <div>
            <div className="tool_con">
                <div className="tool_holder default_bg">
                    <div className="tool_holder_header">
                        <h3 style={{fontWeight: "bolder", textTransform: "uppercase", textAlign: "center"}}> WELEARN CLASSROOM </h3>
                    </div>
                    <form onSubmit={confirmPolicy}>
                        <div className="tool_holder_body class_policy_list" style={{textAlign: "justify"}} >
                            {role===3 && <AdminDuties />}
                            <p style={{lineHeight: "20px"}}>
                                Team
                                <br/>
                                WeLearn Force.
                            </p>
                            
                            <InputMethod name="confirm_rules" label="I have read the policy and job descriptions and I agree" checklist style={{border: "none"}} required/>
                            
                        </div>
                        <div className="tool_holder_footer">
                            <Button name={!disabled?"Continue":"Updating..."} disabled={disabled} form />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ReadPolicy