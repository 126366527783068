import { useCallback, useEffect, useState, useRef } from "react";
import axios from "axios";
import ViewReciept from "./components/ViewReciept";
import Button from "../../../Public/Button";
import { ChevronDoubleUp } from "react-bootstrap-icons";
import ViewTime from "../../../Public/ViewTime";

const ConfirmReceipt = (props) => {
  const [reciept, setReciept] = useState("");
  const [limit, setLimit] = useState(10);
  const [notification, setNotification] = useState([]);
  const [numbEventData, setNumbEventData] = useState("");

  const containerBody = useRef(null);

  useEffect(() => {
    const eventSource = new EventSource(
      "https://server.welearn.com.ng/sse/payment_receipt.php"
    );

    eventSource.onmessage = function (event) {
      try {
        const newMessages = JSON.parse(event.data);
        setNumbEventData(newMessages.length);

        if (!reciept) {
          setReciept(newMessages.slice(0, 10));
        } else {
          const oldMessages = reciept.slice(0, 10);
          if (
            JSON.stringify(oldMessages) !==
            JSON.stringify(newMessages.slice(0, 10))
          ) {
            setNotification(newMessages.slice(0, 10));
          } else {
            setNotification([]);
          }
        }
      } catch (error) {
        console.error("Error parsing SSE data:", error);
      }
    };

    eventSource.onerror = function (error) {
      console.error("EventSource error:", error);
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, [reciept]);

  const editReceipt = (id, val) => {
    const newReceipt = reciept.map((jsonRec) => {
      if (jsonRec.id === id) {
        jsonRec.status = val === "true" ? "1" : "2";
      }
      return jsonRec;
    });
    setReciept(newReceipt);
  };

  const fetchAllReceipts = useCallback(async (limit) => {
    let formData = new FormData();
    formData.append("authorization", "2dkn3KN38JJ*d!den_-dkDN");
    formData.append("run_type", "/admin/fetch_payment_rec");
    formData.append("limit", limit);
    try {
      const response = await axios.post(props.api, formData);
      if (!response.data.length > 0) {
        alert("Receipt not available");
      } else {
        setReciept(response.data);
      }
    } catch (err) {
      alert(
        "Network Error, Please Check connection and refresh the page..."
      );
    }
  }, [props.api]);

  useEffect(() => {
    const handleScroll = () => {
      if (containerBody.current && containerBody.current.scrollTop > 0) {
        // Perform actions based on scroll position
        // console.log(containerBody.current.scrollTop);
      }
    };

    const container = containerBody.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [containerBody, reciept]);

  return (
    <>
      {notification.length > 0 && (
        <Button
          name={
            <>
              <span
                style={{
                  background: "red",
                  display: "block",
                  top: "2px",
                  position: "absolute",
                  right: "0px",
                  width: "10px",
                  height: "10px",
                  borderRadius: "10px",
                }}
              ></span>
              <ChevronDoubleUp />
            </>
          }
          styles={{
            borderRadius: "30px",
            padding: "6px 10px",
            fontSize: "14px",
            textAlign: "center",
            position: "absolute",
            right: "30px",
          }}
          onclick={() => {
            setReciept([...notification, ...reciept.slice(10, reciept.length)]);
            containerBody.current?.scrollTo(0, 0);
          }}
        />
      )}
      {reciept ? (
        reciept.length > 0 ? (
          <>
            <div
              ref={containerBody}
              style={{
                flex: "1",
                overflowY: "scroll",
              }}
            >
              <div className="ele_grid">
                {reciept.map((reciept, key) => (
                  <div key={key}>
                    <div>
                      <span
                        style={{
                          width: "fit-content",
                          height: "fit-content",
                          borderRadius: "50px",
                          padding: "6px 12px",
                          background: "#12529f",
                          color: "#fff",
                          fontSize: "12px",
                        }}
                      >
                        {key + 1}
                      </span>
                      <span style={{ marginLeft: "8px" }}>
                        <ViewTime date={reciept.date_uploaded * 1000} />
                      </span>
                    </div>
                    <ViewReciept
                      delay={key * 900}
                      api={props.api}
                      data={reciept}
                      editReceipt={editReceipt}
                    />
                  </div>
                ))}
              </div>
              {numbEventData > limit && (
                <Button
                  styles={{ display: "block", width: "100%", textAlign: "center" }}
                  name={"Load More"}
                  onclick={() => {
                    fetchAllReceipts(limit + 10);
                    setLimit(limit + 10);
                  }}
                />
              )}
            </div>
          </>
        ) : (
          "No Student Receipt"
        )
      ) : (
        "Please wait"
      )}
    </>
  );
};

export default ConfirmReceipt;
