const AdminDuties = () => {
    return (
        <>
            <b style={{fontSize: "20px"}}>Financial Admin </b>
            <br/>
            <b>Policy, Duties and Job Description</b>
            <ol>
                <li>
                    Must have the capacity to be online 247.
                </li>
                <li>
                    Must be witty, gentle  and pleasant when attending to our customers nationwide
                </li>
                <li>
                    To confirm all payments of students. 
                </li>
                <li>
                    To temporarily deactivate customers from the classroom until their payments are confirmed 
                </li>
                <li>
                    To collaborate with the director and other admins in the WeLearn Force whatsap platform
                </li>
                <li>
                    To suggest ideas on how to keep making the brand the leading provider of online class for west African students 
                </li>
                <li>
                    Should report proactively  on the WeLearn Force platform if she/he will be offline temporarily due to power challenge, or device challenge, or any challenge. Must not be offline in less than 48hours.
                </li>
                <li>
                    Must be present in occasional personal development trainings physically or online to do jobs better.
                </li>
                <li>
                    In charge of calculating tutors payments at the end of the week or month and pay them when need arises 
                </li>
                <li>
                    In charge of crediting referral commission for  students or tutors or school heads that we are in partnership with
                </li>
                <li>
                    In charge of monitoring customers payment plans, sending a reminder to them when they should renew payments,  deactivating them when payments expires.
                </li>
                <li>
                    In charge of all financial reports of cash flow to the director at the end of the month. 
                </li>
                <li>
                    In charge of providing professional customer care to students when they have payment challenges 
                </li>
                <li>
                    To carry out any special duties as instructed by the director.
                </li>
            </ol>
        </>
    )
}

export default AdminDuties