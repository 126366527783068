import { useState } from "react"
import Button from "./Button"
import { X } from "react-bootstrap-icons"

const Img = (props) => {
    const [fView, setFView] = useState(false)
    const viewFullImg = () => {
        setFView(!fView)
    }
    return (
        <>
            {
                !props.no_click ?
                <img src={props.src} alt={props.alt} style={props.style} onClick={viewFullImg} className={props.className} /> :
                <img src={props.src} alt={props.alt} style={props.style} className={props.className} />
            }
            {
                fView ?
                <div style={{
                    position: "fixed",
                    top: "0px",
                    left: "0px",
                    width: "100%",
                    height: "100%",
                    background:"#000",
                    padding: "7px",
                    zIndex: "9999999999"
                }}>
                    <Button name={
                        <X />
                    } styles={{
                        position: "fixed",
                        top: "7px",
                        right: "7px"
                    }} onclick={() => viewFullImg()} error />
                    <img src={props.src} alt="" style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain"
                    }} />
                </div> :
                ""
            }
            
        </>
    )
}

export default Img