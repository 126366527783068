import {  House, HouseFill, Person, PersonFill } from "react-bootstrap-icons"
import Button from "../Public/Button"

const Navigation = (props) => {
    return (
        <div style={{position: "relative"}}>
            <div className="navigation default_bg">
                <Button onclick={() => props.setLink("home")} name={props.link==="home" ? <HouseFill className="nav_btn_ico" />:<House className="nav_btn_ico" />} title="Home" className="nav_btn" />
                <Button onclick={() => props.setLink("dashboard")} name={props.link==="dashboard" ? <PersonFill className="nav_btn_ico" />:<Person className="nav_btn_ico" />} title="Dashboard" className="nav_btn" />
            </div>
            <div className="mv nav_title" style={{textAlign: "center"}}>
                <span onClick={() => props.setLink("home")} style={{color: props.link==="home" ? "#12529f":"#ccc", textAlign: "center"}} title="Home" className="nav_btn_title">Home</span>
                <span onClick={() => props.setLink("dashboard")} style={{color: props.link==="dashboard" ? "#12529f":"#ccc", textAlign: "center"}} title="Dashboard" className="nav_btn_title">Dashboard</span>
            </div>
        </div>
    )
}

export default Navigation