import { Routes, Route } from 'react-router-dom'
import AppStyle from './AppStyle';
import { useEffect, useState } from 'react';
import ServerLoading from '../pages/components/Public/ServerLoading';
import axios from 'axios';
import Cookies from "js-cookie"
import Pusher from 'pusher-js';

import Home from '../pages/Home';
import Logout from '../pages/components/Auth/Logout';
import Signin from '../pages/components/Auth/Signin';

const Appview = (props) => {
    const [disabled, setDisabled] = useState(false)

    const admin_id = Cookies.get("mdjdiowdadmin") || ""

    

    const checkLogin = () => {
        if (admin_id) {
            return(
                <>
                    <Route path="/" element={<Home api={props.api} />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="*" element={<div>Page Unavailable</div>} />
                </>
            )
        } else {
            return (
                <>
                    <Route path="*" element={<Signin api={props.api} winWidth={props.winWidth} />} />
                </>
            )
        }
    }


    return (
        <>
            <ServerLoading display={disabled} />
            <AppStyle api={props.api} theme={true} file={props.file}/>
            <Routes>
                {checkLogin()}
            </Routes>
        </>
    );
   }
   
export default Appview;