import Dashboard from "./subpages/Dashboard"
import Home from "./subpages/Home"

const View = (props) => {
    const setView = (link) => {
        if (link==="home") {
            return <Home api={props.api} file={props.file} paymentPending={props.paymentPending} winWidth={props.winWidth} />
        }
        if (link==="dashboard") {
            return <Dashboard api={props.api} file={props.file} paymentPending={props.paymentPending} winWidth={props.winWidth} />
        }
        return "Not Found"
    }
    return (
        <div className="view ">  
            {setView(props.link)}
        </div>
    )
}

export default View