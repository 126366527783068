import { Link } from "react-router-dom";
import Cookies from "js-cookie"
import { useEffect, useState } from "react";
import { Person, PersonFill } from "react-bootstrap-icons";


const Dashboard = (props) => {
    const [adminDetails, setAdminDetails] = useState([])

    useEffect(() => {
        try {
            setAdminDetails(JSON.parse(Cookies.get("adminDetails")))
        } catch {
            setAdminDetails([])
        }
    }, [])
    return (
        <div style={{padding: "10px"}} className="profile_holder">
            <div className="main_details_holder">
                <div className="my_pp" style={{background: "#12529f"}}>
                    {
                        adminDetails.passport?<img src={props.api+adminDetails.passport} alt="" />: <PersonFill style={{color: "#fff", padding: "4px 8px"}} />
                    }           
                </div>
                <div style={{textAlign: "center", fontWeight: "bolder"}}>
                    {adminDetails.firstname?.toUpperCase()} {adminDetails.middlename?.toUpperCase()} {adminDetails.surname?.toUpperCase()}
                </div>
                <div style={{textAlign: "center"}}>
                    {adminDetails.email}                    
                </div>
                <Link to="logout" style={{
                    background: "#dc3545",
                    color: "#fff",
                    textDecoration: "none",
                    padding: "8px 14px",
                    borderRadius: "4px",
                    display: "block",
                    margin: "3px auto",
                    width: "fit-content"
                }}>Logout</Link>
            </div>
            <div className="other_details_holder">
            <div>
                    <table className="table table-striped" style={{textTransform: "capitalize"}}>
                        <thead>
                            <tr>
                                <th colSpan={2} style={{fontSize: "22px", background: "inherit"}}>
                                    Personal Details
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Gender:
                                </td>
                                <td>
                                    {adminDetails.gender?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Date of Birth:
                                </td>
                                <td>
                                    {adminDetails.dob?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    WhatsApp Number:
                                </td>
                                <td>
                                    {adminDetails.whatsapp?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Other Number:
                                </td>
                                <td>
                                    {adminDetails.phone?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Permanent Address:
                                </td>
                                <td>
                                    {adminDetails.address?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Current Address:
                                </td>
                                <td>
                                    {adminDetails.temp_address?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Guarantor's Name:
                                </td>
                                <td>
                                    {adminDetails.guarantor_name_1?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Guarantor's Phone:
                                </td>
                                <td>
                                    {adminDetails.guarantor_phone_1?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Guarantor's Occupation:
                                </td>
                                <td>
                                    {adminDetails.guarantor_occupation_1?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Guarantor's Relationship:
                                </td>
                                <td>
                                    {adminDetails.guarantor_relationship?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Qualification:
                                </td>
                                <td>
                                    {adminDetails.qualifications_?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Bank Name:
                                </td>
                                <td>
                                    {adminDetails.bank_name?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Account Name:
                                </td>
                                <td>
                                    {adminDetails.acct_name?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Account Number:
                                </td>
                                <td>
                                    {adminDetails.acct_number?.toLowerCase()}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;