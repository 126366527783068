import { At, Whatsapp } from "react-bootstrap-icons"
import Img from "../../../../Public/Img"
import CountdownTimer from "../../../../Public/CountdownTimer"

const StudentView = ({data, api}) => {
    const expDate = new Date(data.payment_exp * 1000);
    const currentDate = new Date();
    const diffTime = expDate - currentDate;
    const daysRem = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            margin: "8px 0px",
            border: ".5px solid #eee",
            padding: "5px 8px"
        }}>
            <div style={{
                textTransform: "capitalize",
                display: "flex",
                flexWrap: "wrap"
            }}>
                <div style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "75px",
                    overflow: "hidden"
                }}>
                    <Img src={api+data.passport} alt="" style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        cursor: "pointer"
                    }} />
                </div>
                <div style={{margin: "auto 8px", fontSize: "14px"}}>
                    {String(data.firstname).toLowerCase()} {String(data.middlename).toLowerCase()} {String(data.surname).toLowerCase()} 
                    <br/>
                    <span style={{fontSize: "13px", display: "flex"}}><Whatsapp style={{margin: "auto 2px", display: "block"}} /><a target="_blank" rel="noreferrer" href={`https://wa.me/${data.whatsapp}?text=Hello`} ><i style={{margin: "auto 2px", display: "block"}}>{data.whatsapp}</i></a></span>
                    <span style={{fontSize: "13px", textTransform: "lowercase", display: "flex"}}><At style={{margin: "auto 2px", display: "block"}} /><i style={{margin: "auto 2px", display: "block"}}>{data.email}</i></span>
                </div>
            </div>
            <hr style={{width: "80%", margin: "8px auto" }} />
            <div style={{

            }}>
                <div>
                    <table className="table table-striped" style={{textTransform: "capitalize", fontSize: "14px"}}>
                        <thead>
                            <tr>
                                <th colSpan={2} style={{fontSize: "14px", background: "inherit"}}>
                                    Other Details
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Gender:
                                </td>
                                <td>
                                    {data.gender.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Date of Birth:
                                </td>
                                <td>
                                    {data.dob.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Other Number:
                                </td>
                                <td>
                                    {data.phone.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Course:
                                </td>
                                <td>
                                    {data.course.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Class Session:
                                </td>
                                <td>
                                    {data.class_session.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Class Type:
                                </td>
                                <td>
                                    {data.class_type.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Payment Status:
                                </td>
                                <td>
                                    {
                                        data.payment_pending==="1" ?
                                        "Pending" :
                                        Number(data.payment_exp)<10000 ?
                                        <>Not in a plan | <a target="_blank" rel="noreferrer" href={`https://wa.me/${data.whatsapp}?text=WeLaern%20Welcome%20%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%E2%9C%85%0AThank%20you%20for%20signing%20up%20on%20WeLaern%20Digital%20classroom.%0A%0AYou%20are%20now%20to%20proceed%20and%20make%20payment%20to%20complete%20your%20sign%20up%20to%20enjoy%20exciting%20learning%20experience.%0A%0AKindly%20chat%20%40%20Support%20admin%2009038692680%20if%20you%20need%20assistance%20in%20payments.%0A%0A%0AThank%20you%20for%20choosing%20WeLaern%20classroom.%0A%0ATeam%0AWeLearn.
                                        `}>Send WhatsApp Message for payment</a></> :
                                        (Number(data.payment_exp)*1000)<Date.now() ?
                                        <span style={{color: "red"}}>Expired <a target="_blank" rel="noreferrer" href={`https://wa.me/${data.whatsapp}?text=Welearn%20classroom%20Renewal%20Notification%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%E2%9C%85%0A%0AYour%20WeLearn%20class%20monthly%20subscription%20has%20expired.%20You%20are%20now%20logged%20out%20temporarily.%20Kindly%20renew%20at%20your%20student%20dashboard%20and%20chat%20support%20admin(%2007063649217)%20to%20add%20you%20back%20to%20the%20classrooms%20to%20continue%20enjoying%20%20your%20classes.%0A%0A%0AThank%20you%20for%20choosing%20Welearn.%0A%0ATeam%0AWeLearn.
                                        `} style={{color: "skyblue"}}>| Send WhatsApp message to repayment</a></span> :
                                        (Number(data.payment_exp)*1000)<Date.now()+(10*24*60*60*1000) ?
                                        <span style={{color: "red"}}> Payment will expire in <CountdownTimer phpTimestamp={data.payment_exp} />  <a target="_blank" rel="noreferrer" href={`https://wa.me/${data.whatsapp}?text=Welearn%20classroom%20Renewal%20Notification%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%E2%9C%85%0A%0AYou%20now%20have%20${daysRem}%20days%20left%20to%20be%20logged%20out%20from%20the%20WeLearn%20classroom.%20Kindly%20renew%20at%20your%20student%20dashboard%20to%20continue%20enjoying%20%20your%20classes.%0AChat%20%40support%20admin%20for%20any%20further%20assistance(09038692680)%0A%0A%0AThank%20you%20for%20choosing%20Welearn.%0A%0ATeam%0AWeLearn.


                                        `} style={{color: "skyblue"}}>| Send WhatsApp Reminder Message</a> </ span> :
                                        <>
                                        Paid - Expires in <CountdownTimer phpTimestamp={data.payment_exp} /> </> 
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default StudentView