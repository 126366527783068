import { useState } from "react";
import StartPage from "./AdminPage/StartPage";
import Button from "../../Public/Button";
import ConfirmReceipt from "./AdminPage/ConfirmReceipt";
import ManageStudents from "./AdminPage/ManageStudents";
import ConfirmTutor from "./AdminPage/ConfirmTutor";

const Home = (props) => {
    const [link, setLink] = useState("/")
    const location = () => {
        if (link==="/") {
            return <StartPage setLink={setLink} api={props.api} />
        }
        if (link==="/conf_pay_rec") {
            return <ConfirmReceipt api={props.api} />
        }
        if (link==="/manage_student") {
            return <ManageStudents api={props.api} />
        }
        if (link==="/ver_tutor") {
            return <ConfirmTutor api={props.api} />
        }
        return <div>Not Found</div>
    }
    return (
        <div style={{
            padding: "8px",
            height: "100%",
            display: "flex",
            flexDirection: "column"
        }}>
            {
                link!=="/" ?
                <>
                    <Button styles={{
                        margin: "5px 0px",
                        display: "block",
                        textAlign: "center"
                    }} onclick={() => setLink("/")} name={
                        <>
                            <span>Home</span>
                        </>
                    } />
                </> :
                ""
            }
            <div className="default_bg" style={{
                padding: "10px",
                overflowY: "hidden",
                flex: "1",
                display: "flex",
                flexDirection: "column",
                
            }}>
                {location()}
            </div>
        </div>
    )
}

export default Home;