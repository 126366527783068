import { useState } from "react"
import Navigation from "./components/Home/Navigation"
import View from "./components/Home/View"
import "./components/Home/Home.css"

const Home = (props) => {
    const [link, setLink] = useState("home")

    return (
        <div>
            <Navigation setLink={setLink} link={link}  winWidth={props.winWidth} />
            <View link={link} api={props.api} file={props.file} winWidth={props.winWidth}/>
        </div>
    )
}
export default Home