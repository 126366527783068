import Button from "../Public/Button"
import InputMethod from "../Public/InputMethod"
import axios from 'axios';
import Cookies from "js-cookie"
import ServerLoading from "../Public/ServerLoading";
import { useState } from "react";

const Signin = (props) => {
    const [disabled, setDisabled] = useState(false)
    
    const admin_id = Cookies.get("mdjdiowdadmin") || ""

    const signinHandler = async (e) => {
        e.preventDefault()
        setDisabled(true)

        const email = e.target.email.value
        const password = e.target.password.value

        
        if (email===""||password==="") {
            alert("Fill all required field")
            return
        }
        
        let formData = new FormData()
        formData.append("authorization","2dkn3KN38JJ*d!den_-dkDN") 
        formData.append("run_type","/admin/signin")
        formData.append("email",email)
        formData.append("password",password)
        try {
            const response = await axios.post(props.api, formData);
            if (response.data.error) {
                alert(response.data.message)
            } else {
                Cookies.set("mdjdiowdadmin",response.data.admin_id,{path: '/', expires: new Date(Date.now()+21212592000)})
                window.location.reload()
            }
            // console.log(response.data)
            setDisabled(false)
        } catch(err) {
            console.log(err)
            setDisabled(false)
        }
    }
    return (
        <div style={{maxWidth: "380px", margin: "3% auto", background: "rgba(0,0,0,.3)", padding: "12px 16px", borderRadius: "8px"}} className="default_bg">
            <ServerLoading display={disabled} />
            <div style={{ marginTop: "0px", marginBottom: "30px"}} >
                <Button name="Sign In" styles={{marginRight:"10px", fontSize: "22px"}} info />
            </div>
            <form onSubmit={signinHandler}>
                <InputMethod label="Email:" name="email"/>
                <InputMethod label="Password:" name="password" password  />
                <div style={{position: "relative", display: "flex"}}>
                    <Button name="Sign In"  styles={{marginTop: "12px", position: "relative", right: "0px"}} form/>
                </div>
            </form>
        </div>
    )
}
export default Signin