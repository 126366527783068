import { useEffect } from "react"
import Cookies from "js-cookie"
import { useNavigate } from "react-router-dom"

const Logout = () => {
    const navigate = useNavigate()
    useEffect(() => {
        let x = window.confirm("Logout?")
        if (x) {
            Cookies.remove("mdjdiowdadmin","",-1)
            navigate("/")
            window.location.reload()
        } else {
            navigate("/")
            return
        }
    })
    return (
        <>

        </>
    )
}

export default Logout