import { useCallback, useEffect, useState } from "react"
import axios from "axios"
import Button from "../../../../Public/Button"
import Cookies from "js-cookie"
import Img from "../../../../Public/Img"
import { Whatsapp } from "react-bootstrap-icons"
import CountdownTimer from "../../../../Public/CountdownTimer"

const ViewReciept = (props) => {
    const [studentDetails, setStudentDetails] = useState("")

    const admin_id = Cookies.get("mdjdiowdadmin") || ""

    const fetchStudentDetails = useCallback( async (student_id) => {
        await new Promise(resolve => setTimeout(resolve, props.delay));
        let formData = new FormData()
        formData.append("authorization","2dkn3KN38JJ*d!den_-dkDN") 
        formData.append("run_type","/student/details")
        formData.append("student_id",student_id)
        
        try {
            const response = await axios.post(props.api, formData);
            if (!response.data.success) {
                alert("Student doesn't exist")
                setStudentDetails("")
            } else {
                setStudentDetails(response.data)
            }
            // console.log(response.data)
        } catch(err) {
            console.log("Network Error, Please Check connection and refresh the page...")
        }
    },[props.api, props.delay])
    const confirmReceipt = async (value) => {
        let formData = new FormData()
        formData.append("authorization","2dkn3KN38JJ*d!den_-dkDN") 
        formData.append("run_type","/admin/confirm_payment_rec")
        formData.append("student_id",props.data.student_id)
        formData.append("admin_id",admin_id)
        formData.append("receipt_id",props.data.id)
        formData.append("value",value)
        
        try {
            const response = await axios.post(props.api, formData);
            if (!response.data.success) {
                alert("Failed!")
                return
            } 
            props.editReceipt(props.data.id, value)
            // console.log(response.data)
        } catch(err) {
            alert("Network Error, Please Check connection and try again")
            console.log(err)
        }
    }
    useEffect(() => {
        if (props.data) {
            fetchStudentDetails(props.data.student_id)
        }
    }, [fetchStudentDetails, props.data])
    return (
        studentDetails ?
        <div style={{
            margin: "12px auto",
            padding: "8px 12px",
            background: "#f2f2f2",
            borderRadius: "4px", 
        }}>
            <div style={{
                textTransform: "uppercase",
                display: "flex",
                flexWrap: "wrap"
            }}>
                <div style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "75px",
                    overflow: "hidden"
                }}>
                    <Img src={props.api+studentDetails.passport} alt="" style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        cursor: "pointer"
                    }} />
                </div>
                <div style={{margin: "auto 8px", fontSize: "14px"}}>
                    {String(studentDetails.firstname).toLowerCase()} {String(studentDetails.middlename).toLowerCase()} {String(studentDetails.surname).toLowerCase()} 
                    <span style={{fontSize: "13px", display: "flex"}}><Whatsapp style={{margin: "auto 2px", display: "block"}} /><i style={{margin: "auto 2px", display: "block"}}>{studentDetails.whatsapp}</i></span>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "column", fontSize: "14px"}}>
                <div>Amount: &#8358;{props.data.amount}</div>
                <div>Payment Plan: {Math.floor((((Number(props.data.duration)*1000)-(Number(props.data.date_uploaded)*1000)+100000)) / (1000 * 60 * 60 * 24 * 30))} Month{(Math.floor((((Number(props.data.duration)*1000)-(Number(props.data.date_uploaded)*1000)+100000)) / (1000 * 60 * 60 * 24 * 30))>1 ? "s" : "")}</div>
                {Number(props.data.status)=== 0 || Number(props.data.status)===1 ? <div>Duration: <CountdownTimer phpTimestamp={props.data.duration} /></div> :
                ""}
            </div>
            <div style={{
                    width: "120px",
                    height: "120px",
                    overflow: "hidden",
                    margin: "8px auto"
                }}>
                <Img src={props.api+props.data.receipt} alt="" style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    cursor: "pointer"
                }} />
            </div>
            <div>
                {
                    Number(props.data.status)===0 ?
                    <div className="xsm_ele_grid" >
                        <Button name="Confirm" onclick={() => {
                            if (window.confirm("Confirm Receipt?")) {
                                confirmReceipt("true")
                            }
                        }} success />
                        <Button name="Reject" onclick={() =>{
                            if (window.confirm("Reject Receipt?")) {
                                confirmReceipt("false")
                            }
                        }} error />
                    </div> :
                    Number(props.data.status)===1 ?
                    <div>
                        <Button name={`Confirmed by ${props.data.admin_id===admin_id?"you":props.data.admin_id}`} styles={{fontStyle: "italic", fontSize: "12px"}} success disabled />
                    </div> :
                    <div>
                        <Button name={`Rejected by ${props.data.admin_id===admin_id?"you":props.data.admin_id}`} styles={{fontStyle: "italic", fontSize: "12px"}} error disabled />
                    </div> 
                }
            </div>
        </div>
        :
        "Loading..."
    )
}

export default ViewReciept